<template>
  <div class="main_profile_wrapper">
    <!-- Start Patterns -->
    <UserPattern :userScore="userScore"></UserPattern>
    <!-- End Patterns -->

    <div class="mt-5">
      <SharePrintCard
        title="Your Personal Work Profile (PWP)"
        text="View, edit, or share your PWP to get accurate strategies for your next career opportunity."
        @print="printPlp"
        :displayPrint="false"
      ></SharePrintCard>
    </div>

    <!-- Table And Data -->
    <div class="main_table">
      <TableHeaderSection
        @toggleModal="ToggleTaskModal('add')"
        title="Added Jobs"
        text="Manage and edit your jobs or add new ones."
        buttonText="Add new job"
        :total="careerMatchTasksCount"
      ></TableHeaderSection>

      <!-- Start Main Table Here  padding -->
      <div class="header_container just_custom_table_tasks">
        <CustomTable
          class=""
          :count="careerMatchTasksCount"
          :DataItems="careerMatchTasks"
          @Refresh="refresh"
          @reset="filter = {}"
          :filter="filter"
          :title="'Tasks'"
          hidden_export_import
          admin_permission="admin_tasks"
          edit_permission="add_tasks"
          @selection="selections"
        >
          <!-- search & filter fields  -->
          <!-- <template #filter>
            <el-select
              v-model="filter.topic_id"
              clearable
              placeholder="Topic - All"
              class="select_filter_custom"
            >
              <el-option
                v-for="(option, index) in careerMatchTasksTopics"
                :key="index"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </template> -->

          <!-- Tasks Table  -->
          <template #columns>
            <!-- Select Users -->
            <el-table-column type="selection" width="80"></el-table-column>

            <!-- Task Name  -->
            <el-table-column
              prop="name"
              sortable
              :label="`${localization('Job Name')}`"
            ></el-table-column>
            <!-- Task Topic -->
            <!-- <el-table-column
              prop="topic_name"
              sortable
              :label="`${localization('Topic')}`"
              class="d-none d-md-block"
            ></el-table-column> -->

            <!-- //? Task Actions  -->
            <el-table-column prop="" label="">
              <template slot-scope="scope">
                <div class="td_actions flex justify-content-end">
                  <!-- show task button  -->
                  <router-link
                    v-if="hasPermission('show_tasks')"
                    :to="`/new-theme/career/${scope.row.id}`"
                    v-tooltip.top-center="
                      `${localization('Show personal strategy')}`
                    "
                    class="btn btn-info btn-link btn-xs"
                  >
                    <img
                      src="@/assets/new-theme/eye-inactive.png"
                      alt="eye-inactive"
                    />
                  </router-link>
                  <!-- Edit Task Button  -->
                  <a
                    v-if="hasPermission('edit_tasks')"
                    v-tooltip.top-center="
                      `${localization('Edit personal strategy')}`
                    "
                    class="btn btn-warning btn-simple btn-link"
                    @click="ToggleTaskModalForEdit('edit', scope.row)"
                  >
                    <img
                      src="@/assets/new-theme/edit-05-inactive.png"
                      alt="edit-05-inactive"
                    />
                  </a>
                  <span class="btn btn-warning btn-simple btn-link">
                    <img
                      src="@/assets/new-theme/printer-inactive.png"
                      alt="printer-inactive"
                    />
                  </span>
                </div>
              </template>
            </el-table-column>
          </template>
        </CustomTable>
        <DeleteTasks
          v-if="selected.length > 0"
          :text="`${selected.length} tasks selected`"
          @close="resetSelections"
          @print="printTasks"
          @delete="deleteTasks"
        ></DeleteTasks>
      </div>
      <!-- End Main Table Here -->
    </div>
    <!-- End Table And Data -->

    <!-- Task Modal -->
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="600px"
      top="1%"
      custom-class="task-modal global_modal"
      @close="resetForm"
      :close-on-click-modal="false"
    >
      <div class="custom_modal_header">
        <div class="images">
          <img src="@/assets/new-theme/file.png" alt="file" />
          <img
            @click="resetForm"
            class="close_img"
            src="@/assets/new-theme/x-close-modal.png"
            alt="file"
          />
        </div>
        <h3 class="title">
          {{ type == "add" ? "Add a new" : "Edit your" }} job-related task
        </h3>
        <p class="description">
          Add your own job-related task and discover what patterns are needed to
          successfully complete it.
        </p>
      </div>

      <!-- Task Form -->
      <el-form :model="task" :rules="rules" ref="taskForm" class="task-form">
        <div class="flex justify-content-between" style="gap: 10px">
          <div class="w-50">
            <!-- Task Name -->
            <el-form-item prop="name">
              <AppInputLabel text="Name your task" :star="true"></AppInputLabel>
              <el-input
                class="new_theme_custom_input"
                v-model="task.name"
                placeholder="Give you task a name"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <!-- Task Topic -->
          <!-- <div class="flex-grow-1">
            <el-form-item prop="topic_id">
              <AppInputLabel text="Task category" :star="true"></AppInputLabel>
              <el-select
                v-model="task.topic_id"
                clearable
                :placeholder="`${localization('Topic')}`"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in careerMatchTasksTopics"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div> -->
        </div>

        <!-- Task Description -->
        <el-form-item prop="description">
          <AppInputLabel text="Task description" :star="true"></AppInputLabel>
          <el-input
            class="new_theme_custom_input input_with_custom_text_area"
            type="textarea"
            v-model="task.description"
            placeholder="Add task description"
            clearable
          />
        </el-form-item>
      </el-form>
      <div class="or_modal mb-3">or</div>
      <!-- File Upload Section -->
      <div class="file-upload">
        <el-upload
          class="upload-demo"
          drag
          action=""
          :on-change="handleUploadSuccess"
          :before-upload="beforeUpload"
          :file-list="fileList"
          :auto-upload="false"
          :on-remove="handleRemove"
          :show-file-list="false"
          accept="image/apng, image/jpeg, image/png, image/webp"
          :disabled="loading"
        >
          <div v-if="!loading">
            <img src="@/assets/new-theme/upload.png" alt="upload" />
            <div class="el-upload__text">
              <span class="click_upload">Click to upload</span> or drag and drop
            </div>
            <div class="el-upload__tip">
              Supported: PNG, JPG, (Max 5MB)
            </div>
          </div>
          <div
            class="d-flex spin_modal"
            v-loading="loading"
            element-loading-text="waiting to extract photo..."
          ></div>
        </el-upload>
      </div>

      <!-- Display Uploaded Files with Delete Option -->
      <div v-if="fileList && fileList.length > 0" class="uploaded-files">
        <ul>
          <li v-for="file in fileList" :key="file.name" class="file-item">
            <div class="d-flex" style="gap: 10px">
              <div>
                <img src="@/assets/new-theme/file.png" alt="" />
              </div>
              <div>
                <span class="file-name">{{ file.name }}</span>
                <span class="d-block file_size"
                  >{{ (file.size / 1024).toFixed(2) }} KB</span
                >
              </div>
            </div>
            <i class="el-icon-delete file-delete" @click="removeFile(file)"></i>
          </li>
        </ul>
      </div>

      <!-- Footer Buttons -->
      <div class="lci_modal_buttons mt-4">
        <button @click="resetForm" class="lci_btn_modal_cancel ">
          Cancel
        </button>
        <button @click="submitForm" class="lci_btn save  lci_btn_modal_cancel">
          Get strategies!
        </button>
      </div>
    </el-dialog>
    <!-- End Add Task Modal -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "CareerView",

  data() {
    return {
      showAddTask: false,
      dialogVisible: false,
      taskTypes: ["Study-related task", "Work-related task"],
      task: {
        name: "",
        topic_id: "",
        description: ""
      },
      fileList: [],
      uploadPercentage: 0, // Upload progress percentage
      rules: {
        name: [
          {
            required: true,
            message: "Please enter the task name",
            trigger: "blur"
          }
        ],
        topic_id: [
          {
            required: true,
            message: "Please enter the task topic",
            trigger: "blur"
          }
        ],
        description: [
          {
            required: true,
            message: "Please enter a task description",
            trigger: "blur"
          }
        ]
      },
      filter: {},
      selected: [],
      loading: false,
      task_id: null,
      type: ""
    };
  },
  computed: {
    mainUserId() {
      return this.$route.params.id ? this.$route.params.id : this.auth().id;
    },
    userScore() {
      return this.$store.getters["user/score"];
    },
    userData() {
      return this.$store.state.user.user;
    },
    image() {
      if (this.userData.image) {
        return `${process.env.VUE_APP_BASE_URL}/users/photo/${this.userData.image}`;
      }
      return "/static/img/lml-swerl.jpg";
    },
    // Start Tasks At Work
    careerMatchTasks() {
      return this.$store.getters["user/getCareerMatchTasks"];
    },
    careerMatchTasksCount() {
      return this.$store.getters["user/getCareerMatchTasksCount"];
    },
    careerMatchTasksTopics() {
      return this.$store.getters["user/getCareerMatchTasksTopics"];
    }
  },
  methods: {
    // Get User Data And Set User Score
    // Use First:35 - 25
    // As Needed:24 - 18
    // Avoid:17 - 7
    getMainUserData() {
      this.$store.dispatch("user/user", this.mainUserId).then(() => {
        // Set the Selected User attribute
        // this.selected = this.userData.selected_attributes;
      });
    },
    getProgressColor(key) {
      switch (key) {
        case "Precision":
          return "#3c3";
        case "Technical Reasoning":
          return "#f93";
        case "Confluence":
          return "#c36";
        case "Sequence":
          return "#39c";
      }
    },
    ToggleTaskModal(type) {
      this.showAddTask = !this.showAddTask;
      this.dialogVisible = !this.dialogVisible;
      this.type = type;
    },
    ToggleTaskModalForEdit(type, taskData) {
      this.type = type;
      this.task_id = taskData.id;
      this.task.name = taskData.name;
      this.task.description = taskData.description;
      this.task.topic_id = taskData.topic_id;
      this.dialogVisible = !this.dialogVisible;
    },
    refresh(query) {
      this.$store
        .dispatch("user/handlerCareerMatchTasks", {
          id: this.mainUserId,
          query: query
        })
        .then(() => {
          this.$store.dispatch("topics/GetTopics", { query: null });
        });
      // this.$store.dispatch("user/handlerHowToUseYourPatterns");
      // this.$store.dispatch("topics/handlerStandardTopics");
      this.$store.dispatch("user/handlerCareerMatchTopics");
    },
    selections(selections) {
      this.selected = Array.from(selections, el => el.id);
      console.log(this.selected);
      // this.selectedInst.users = Array.from(selections, el => el.id);
    },
    resetSelections() {
      this.selected = [];
      this.$refs.customTable.clearSelection();
    },

    handleUploadSuccess(file, fileList) {
      this.fileList = fileList;
      this.handle_upload_file_img(file);
    },

    beforeUpload(file) {
      console.log("🚀 ~ beforeUpload ~ file:", file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("File size cannot exceed 5MB.");
      }
      return isLt5M;
    },
    handleRemove(file, fileList) {
      this.fileList = [];
    },
    removeFile(file) {
      const index = this.fileList.indexOf(file);
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    handle_upload_file_img(file) {
      this.loading = true;
      this.description_photo = file.raw;
      let payload = new FormData();
      payload.append("photo", file.raw);
      this.$store
        .dispatch("user/get_ocr_photo_description", payload)
        .then(response => {
          this.task.description = response.photo_description;
          this.loading = false;
          Swal.fire({
            title: "",
            icon: "success",
            text: `${this.localization("Check Extracted Photo")}`,
            type: "success",
            confirmButtonText: `${this.localization("Ok")}`,
            confirmButtonClass: "btn btn-success ",
            buttonsStyling: false
          });
        });
      this.fileList = [];
      this.loading = false;
    },

    // End Extract Photo
    submitForm() {
      this.$refs.taskForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.$store
              .dispatch("user/handleAddrCareerMatchTask", {
                query: this.task
              })
              .then(response => {
                this.dialogVisible = false;
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.refresh({});
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          } else {
            this.$store
              .dispatch("user/handleEditCareerMatchTask", {
                query: this.task,
                id: this.task_id
              })
              .then(_ => {
                this.dialogVisible = false;

                Swal.fire({
                  title: "",
                  icon: "success",
                  text: `${this.localization("Done")}`,
                  confirmButtonText: `${this.localization("Ok")}`,
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });

                this.refresh({});
                // this.$router.push(`/new-theme/tasks/${this.task_id}`);
              })
              .catch(_ => {
                this.dialogVisible = false;
              });
          }
        } else {
          // this.$message.success("Form is valid. Strategies will be generated.");
          // this.$message.error("Please fill out all required fields.");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.taskForm.resetFields();
      this.fileList = [];
      this.dialogVisible = false;
    },
    printPlp() {
      window.print();
    },
    deleteTasks() {
      Swal.fire({
        title: "",
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        icon: "error",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success ",
        cancelButtonClass: "btn btn-danger ",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false
      }).then(date => {
        if (date.isConfirmed) {
          let payload = {
            tasks: this.selected
          };
          this.$store
            .dispatch("user/handleDeleteCareerMatchTask", payload)
            .then(_ => {
              this.refresh(null);
              Swal.fire({
                title: "",
                icon: "success",
                type: "success",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                confirmButtonClass: "btn btn-success ",
                buttonsStyling: false
              });
            });
        }
      });
    },
    printTasks() {
      let payload = {
        tasks: this.selected
      };
      this.$store.dispatch("user/handlePrintCareerTasks", payload).then(() => {
        window.print();
        this.Refresh();
      });
    }
  },
  mounted() {
    this.getMainUserData();
    this.refresh();
  }
};
</script>

<style lang="scss" scoped>
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
}
.lci_modal_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3%;
  .lci_btn_modal_cancel {
    background-color: #fff;
    color: #667085;
    border-radius: 8px;
    padding: 12px 20px;
    border: 1px solid #eaecf0;
    width: calc(97% / 2);
    &.save {
      background-color: #7f56d9;
      color: #fff;
    }
  }
}
.main_custom_parts_pattern {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  .part_pattern {
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 16px;
    height: 25px;
    padding: 10px 13px;
    width: 55px;
    font-weight: 500;
    &.s {
      color: #39c;
      border-color: #39c;
    }
    &.p {
      color: #3c3;
      border-color: #3c3;
    }
    &.t {
      color: #f93;
      border-color: #f93;
    }
    &.c {
      color: #c36;
      border-color: #c36;
    }
  }
}
.text {
  color: #667085;
  font-weight: 400;
  font-size: 16px;
}
.main_profile_wrapper {
  background: #fff;
  width: 100%;
  max-width: 100%;
  padding: 0 30px 50px;
}
.main_title {
  color: #101828;
  font-weight: 600;
  font-size: 20px;
  &.white_text {
    color: #fff;
  }
}
.padding {
  padding: 14px;
}
.main_table {
  margin-top: 30px;
  border-radius: 8px;
  border: 1px solid #eaecf0;

  .head_table {
    border-bottom: 1px solid #eaecf0;
  }
}
</style>

<style scoped>
.task-modal {
  padding: 20px;
}
.task-type {
  margin-bottom: 20px;
}
.task-form {
  margin-bottom: 20px;
}
.file-upload {
  margin-bottom: 20px;
  text-align: center;
}
.uploaded-files {
  margin: 20px 0;
}
.uploaded-files h4 {
  margin-bottom: 10px;
}
.uploaded-files ul {
  list-style: none;
  padding: 0;
}
.uploaded-files li {
  font-size: 14px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.uploaded-files li .el-icon-document {
  margin-right: 10px;
}
.file-delete {
  cursor: pointer;
  color: red;
  margin-left: 10px;
  font-size: 18px;
}
.el-upload {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 5px;
}
.el-upload__text {
  color: #409eff;
  font-size: 16px;
}
.el-upload__tip {
  color: #909399;
}
.dialog-footer {
  text-align: right;
}
.el-button {
  margin-left: 10px;
}
.el-progress {
  margin: 20px 0;
}

/* Custom Radio Buttons */
.custom-radio-group {
  display: flex;
  justify-content: space-between;
}

.custom-radio {
  display: inline-flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.custom-radio.is-checked {
  border-color: #67c23a;
  background-color: rgba(103, 194, 58, 0.1);
}
</style>
